var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-resource fade-up"},[_c('el-row',{staticStyle:{"position":"relative"},attrs:{"gutter":30}},[_c('el-col',{attrs:{"span":16}},[_c('el-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.langLoading),expression:"langLoading"}],staticClass:"resource-content-card"},[_c('div',{staticClass:"card-header",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"lang-section"},[_c('h3',[_vm._v("Тилни танланг:")]),_c('el-radio-group',{model:{value:(_vm.selectedLang),callback:function ($$v) {_vm.selectedLang=$$v},expression:"selectedLang"}},[_c('el-radio-button',{attrs:{"label":"oz"}},[_vm._v("O'zbekcha")]),_c('el-radio-button',{attrs:{"label":"uz"}},[_vm._v("Узбекча")])],1)],1)]),_c('div',{staticClass:"title-section",class:{
            'invalid-error': _vm.$v.form[("title_" + _vm.selectedLang)].$error
          }},[_c('h3',[_vm._v("Номи")]),_c('el-input',{attrs:{"size":"large","placeholder":"Номини киритинг"},model:{value:(_vm.$v.form[("title_" + _vm.selectedLang)].$model),callback:function ($$v) {_vm.$set(_vm.$v.form[("title_" + _vm.selectedLang)], "$model", $$v)},expression:"$v.form[`title_${selectedLang}`].$model"}})],1),_c('div',{staticClass:"desc-section"},[_c('div',{staticClass:"desc-row"},[_c('h3',[_vm._v("Қисқа тавсиф")]),_c('small',[_vm._v(_vm._s(_vm.form[("description_" + _vm.selectedLang)].length)+"/600")])]),_c('el-input',{attrs:{"size":"large","type":"textarea","maxlength":"600","placeholder":"Тавсифни киритинг"},model:{value:(_vm.form[("description_" + _vm.selectedLang)]),callback:function ($$v) {_vm.$set(_vm.form, ("description_" + _vm.selectedLang), $$v)},expression:"form[`description_${selectedLang}`]"}})],1),_c('div',{staticClass:"content-section"},[_c('h3',[_vm._v("Контент")]),_c('quill-editor',{staticClass:"editor",model:{value:(_vm.form[("content_" + _vm.selectedLang)]),callback:function ($$v) {_vm.$set(_vm.form, ("content_" + _vm.selectedLang), $$v)},expression:"form[`content_${selectedLang}`]"}})],1)])],1),_c('el-col',{staticStyle:{"position":"sticky","top":"0"},attrs:{"span":8}},[_c('el-card',[_c('div',{staticClass:"date-section",class:{
            'invalid-error': _vm.$v.form.publish_at.$error
          }},[_c('h4',[_vm._v("Нашр этиш вақти")]),_c('el-date-picker',{staticClass:"date-input",attrs:{"type":"datetime","placeholder":"Санани танланг"},model:{value:(_vm.$v.form.publish_at.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.publish_at, "$model", $$v)},expression:"$v.form.publish_at.$model"}})],1),_c('div',{staticClass:"category-section",class:{
            'invalid-error': _vm.$v.form.category.$error
          }},[_c('h4',[_vm._v("Категория")]),_c('el-select',{attrs:{"placeholder":"Категория танланг"},model:{value:(_vm.$v.form.category.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.category, "$model", $$v)},expression:"$v.form.category.$model"}},_vm._l((_vm.categories),function(cat){return _c('el-option',{key:cat.id,attrs:{"value":cat.id,"label":cat.name_uz}})}),1)],1),_c('div',{staticClass:"photo-upload-section",class:{
            'invalid-error': _vm.$v.thumbnail.$error
          }},[_c('h4',[_vm._v("Расм")]),_c('drop-file-input',{model:{value:(_vm.$v.thumbnail.$model),callback:function ($$v) {_vm.$set(_vm.$v.thumbnail, "$model", $$v)},expression:"$v.thumbnail.$model"}})],1),_c('div',{staticClass:"flex-end"},[_c('el-button',{attrs:{"type":"success","icon":"el-icon-check"},on:{"click":_vm.addResource}},[_vm._v(" Қушиш ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }